@import url(https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@600&display=swap);
@font-face {
  font-family: Browallia New;
  src: url(/static/media/browa.38a27e8d.ttf);
}

@font-face {
  font-family: Aharoni;
  src: url(/static/media/aharoni.32ff5fe0.woff2);
}


@font-face {
  font-family: ArTarumianHelevetacia;
  src: url("/static/media/Arm Helvetica.721bda57.ttf");
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  outline: none;
}
html {
  scroll-behavior: smooth;
}
:root{
  --bac-font: Browallia New;
  --font-size:11px;
  --bold-font:Aharoni;
  --menu-width: 8vw;
  --menu-4th-column-width: 160px;
  --menu-4th-5th-column-width: 200px;
}

.menu-icon, .menu-black-icon {
  -webkit-mask-image: url(/static/media/menu.efcdf31b.svg) !important;
          mask-image: url(/static/media/menu.efcdf31b.svg) !important;
  -webkit-mask-size: 27px 27px;
          mask-size: 27px 27px;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
  width: 27px;
  height: 27px;
}



.home-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 45px 141px 0 50px;
}
.header-logo-text {
  font-family: Source Serif Pro;
  font-weight: 600;
  font-size: 50px;
  line-height: 63px;
  letter-spacing: 0.12em;
  color: #fff;
}
.header-logo-black-text {
  font-family: Source Serif Pro;
  font-weight: 600;
  font-size: 50px;
  line-height: 63px;
  letter-spacing: 0.12em;
  color: #434b62;
}
.close-icon,
.social-media-icons img,
.menu-icon {
  cursor: pointer;
}

.close-icon {
  width: 20px;
  height: 11px;
  -webkit-mask-image: url(/static/media/close-button.95f1063b.svg);
          mask-image: url(/static/media/close-button.95f1063b.svg);
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
  -webkit-mask-size: cover;
          mask-size: cover;
  background-color: black;
}

.menu-icon {
  background-color: black !important;
  background-size: contain;
  background-repeat: no-repeat;
  width: 30px;
  height: 42px;
}
.awssld {
  z-index: -10;
  --loader-bar-height: 0px;
}
.awssld__timer,
.awssld__timer--run {
  opacity: 0;
  height: 0 !important;
}
.awssld__bullets {
  z-index: 10;
  bottom: 35.5px;
}
.awssld__bullets button {
  width: 14px;
  height: 14px;
  background: rgba(203, 203, 203, 0.59);
  transform: scale(1);
}
.awssld__bullets--active {
  background-color: #fff !important;
}
.awssld__bullets .awssld__bullets--active {
  transform: scale(1.2);
}
.menu {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  display: grid;
  grid-template-rows: auto 1fr;
  z-index: 11;
  animation-name: menu-anim;
}
@keyframes menu-anim {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.menu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 35px 93px 0 50px;
}
.menu-logo h3 {
  font-family: Source Serif Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 63px;
  letter-spacing: 0.12em;
  color: #434b62;
}

.logo-image {
  width: 200px;
  height: 62px;
  object-fit: cover;
}

.white-logo-image {
  background-color: black !important;
  width: 200px;
  height: 62px;
  -webkit-mask-image: url(/static/media/anarch-logo-black.c31e8058.svg);
  mask-image: url(/static/media/anarch-logo-black.c31e8058.svg);
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-position: center;
  mask-position: center;
}

.menu-pageheader {
  font-family: var(--bold-font);
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  color: #000000;
}
.menu-page dt {
  margin-bottom: 10px;
}
.pages a {
  font-family: var(--bac-font);
  font-weight: normal;
  line-height: 28px;
  color: #000;
}
.menu-page:not(:first-child) {
  margin-left: 4vw;
  width: 10vw;
}
.menu-container {
  margin: auto;
  display: grid;
  grid-template-rows: repeat(2, -webkit-max-content);
  grid-template-rows: repeat(2, max-content);
  align-items: center;
  max-height: -webkit-fill-available;
  overflow: auto;
}
.menu-columns {
  width: 100%;
}
.menu-item-titles {
  display: grid;
  grid-template-columns: 3.2vw 8vw 8vw 9vw 8vw;
  grid-column-gap: 6.7vw;
  align-items: flex-end;
  margin-bottom: 10px;
}

.menu-items {
  display: grid;
  grid-template-columns: 3.2vw 8vw 8vw 9vw 8vw;
  grid-column-gap: 6.7vw;
}

@media screen and (max-width: 1620px) {
  .menu-item-titles {
    grid-template-columns: 3.2vw 8.8vw 9vw 10vw 8.8vw;
  }
  .menu-items {
    grid-template-columns: 3.2vw 8.8vw 9vw 10vw 8.8vw;
  }
}

@media screen and (max-width: 1460px) {
  .menu-item-titles {
    grid-template-columns: 5.2vw 10.8vw 10.9vw 12.4vw 10.7vw;
  }
  .menu-items {
    grid-template-columns: 5.2vw 10.8vw 10.9vw 12.4vw 10.7vw;
  }
}

@media screen and (max-width: 1200px) {
  .menu-item-titles {
    grid-template-columns: 5.2vw 14.4vw 14.4vw 14.4vw 14.4vw;
  }
  .menu-items {
    grid-template-columns: 5.2vw 14.4vw 14.4vw 14.4vw 14.4vw;
  }
}

.lang-am .menu-item-titles {
  grid-template-columns:
    7vw 11vw 11vw var(--menu-4th-column-width)
    var(--menu-4th-5th-column-width);
}
.lang-am .menu-items {
  grid-template-columns:
    7vw 11vw 11vw var(--menu-4th-column-width)
    var(--menu-4th-5th-column-width);
}
.menu-items > div a {
  font-family: var(--bac-font);
  font-size: 25px;
  font-style: normal;
  font-weight: normal;
  line-height: 28px;
  color: #000;
}

.lang-am:not(.App) {
  font-size: 14px;
  padding: 0 10vw;
}
.menu-item-title {
  font-family: var(--bold-font);
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
  color: #000000;
}
.lang-am .menu-item-title {
  font-size: 14px !important;
}
.lang-am .menu-items > div a {
  font-size: 13px !important;
}

.menu-footer-languages p {
  font-family: var(--bac-font);
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: #000000;
  cursor: pointer;
}
.lang-am .menu-footer-languages p {
  font-size: 13px;
}
.lang-am .active-language {
  font-size: 12px !important;
}
.active-language {
  font-family: var(--bold-font) !important;
  font-weight: bold !important;
  letter-spacing: 0.05em !important;
}
.menu-footer {
  margin-top: 10vh;
}
.social-media-icons {
  margin-bottom: 15px;
}
.instagram-icon {
  margin-left: 10px;
}

@media (max-width: 1050px) {
  .menu-item-title {
    font-size: 1.5vw;
  }
}

@media (max-width: 881px) {
  .home-header {
    padding: 30px 40px !important;
  }
  .menu-header {
    padding: 40px 30px !important;
  }
  .home-page .menu-icon {
    background-color: black !important;
  }
  .home-page .header-logo h3 {
    color: #fff !important;
  }

  .logo-image{
    width: 196px;
    height: 61px;
  }

  .menu-logo h3 {
    font-size: 35px !important;
    line-height: 44px !important;
  }
  .menu-container {
    margin: unset !important;
  }
  .pages {
    flex-direction: column;
    padding-left: 10vw;
  }
  .menu-page {
    width: unset !important;
    margin: 10px 0 !important;
  }
  .lang-am .menu-pageheader{
    font-size: 14px !important;
  }
  .menu-pageheader {
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.05em;
  }
  .lang-am .pages a{
    font-size: 13px !important;
  }
  .menu-footer {
    margin-top: 0 !important;
    padding-top: 40px;
    padding-left: 10vw;
    padding: 40px 0 159px 10vw;
  }
  .active-language {
    font-size: 22px;
    line-height: 25px;
    letter-spacing: 0.05em;
  }
  .menu-footer-languages p {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
  }
}

@media (max-width: 425px) {
  .home-header {
    padding: 33px 0 0 30px !important;
  }
  .home-header-logo h3 {
    padding: 0 20px;
  }
  .menu-page-header {
    font-family: Source Serif Pro;
    font-size: 16px;
    line-height: 20px;
  }
  .menu-pageheader {
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.05em;
  }
  .lang-eng .pages dd {
    font-size: 20px;
  }
  .pages dd {
    line-height: 22.59px;
    letter-spacing: 0.05em;
  }
}

.footer-social-media-icons {
  cursor: pointer;
  padding-right: 20px;
  align-self: flex-end;
}
.footer-social-media-icons a:not(:last-child){
  margin-right: 20px;
}
.footer-languages {
  margin-left: 1vw;
  padding-left: 20px;
  border-left: 1px solid rgba(94, 94, 94, 0.5);
  cursor: pointer;
}
.footer-languages ul {
  list-style: none;
  position: relative;
}
.footer-languages ul li {
  display: flex;
  align-items: center;
  background: #f9f9f9;
}
.footer-languages ul li p {
  margin-left: 0.5vw;
  font-family: var( --bac-font );
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.05em;
  color: #5d5d5d;
}
.hidden-languages {
  margin-bottom: 5px;
  position: absolute;
  top: -30px;
}
@media (max-width: 749px) {
  .language-icon {
    width: 24px;
    height: 24px;
  }
}

.studio-contact {
  position: relative;
}
.studio-contact-hide {
  height: 100vh;
  overflow: hidden;
}
.studio-contact-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 29px 140px 0 50px;
}
.studio-contact-content {
  display: grid;
  grid-template-columns: 50% 50%;
}
.studio-contact-page {
  display: none;
}
.studio-contact-left-bar {
  background-size: cover;
  height: 990px;
}
.studio-contact-right-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 140px 0 92px;
}
.contact-address {
  width: 12vw;
}

.lang-eng .contact-address {
  font-family: var(--bac-font);
  font-size: 21px;
}

.contact-header-logo {
  text-align: right;
  margin-right: 0 !important;
}
.contact-info-header {
  font-family: var(--bac-font);
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
}
.lang-am .contact-info-header {
  font-size: 14px !important;
}
.contact-right-bar-content p {
  font-family: var(--bac-font);
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
}
.lang-am .studio-contact-right-bar p {
  font-size: 13px !important;
}
.contact-menu-icon {
  margin: 2vw 0 0 2vw;
}
.contact-email {
  color: #000;
  height: 22px;
  display: inline-block;
}

.lang-eng .contact-email {
  font-family: var(--bac-font);
  font-size: 21px;
}
.lang-am .contact-email{
  font-size: 16px;
}

.contact-info-email {
  margin-top: 2vh;
}
@media (max-width: 880px) {
  .studio-contact-header {
    padding: 41.8px 0 20px 36px;
  }
  .menu-icon {
    background-color: white !important;
  }
  .header-logo h3 {
    color: #fff !important;
  }
  .studio-contact-content {
    display: grid;
    grid-template-columns: 1fr !important;
  }
  .studio-page-name {
    padding: 20px 0 25px 36px;
  }
  .studio-contact-left-bar {
    height: 805px;
  }
  .studio-contact-right-bar {
    padding: 25px 36px 0 36px;
  }
  .studio-contact-right-bar h3 {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
  }
  .studio-contact-right-bar p,
  .studio-contact-right-bar span,
  .studio-contact-right-bar a {
    font-size: 18px !important;
    line-height: 18px;
  }
  .contact-address {
    width: 270px;
  }
}
@media (max-width: 425px) {
  .studio-contact-header {
    padding: 33px 22px 19px 30px;
  }
  .menu-black-icon {
    background-color: black !important;
  }
  .header-logo h3 {
    color: #434b62 !important;
  }
  .black-logo h3 {
    font-size: 35px !important;
    line-height: 44px !important;
  }

  .studio-contact-page {
    display: none;
  }
  .studio-contact-left-bar {
    height: 475px;
    margin-top: 0;
  }

  .studio-contact-right-bar {
    padding: 30px 20px 43px 20px;
  }
  .studio-contact-right-bar h3 {
    font-weight: 700;
    font-size: 14px !important;
    line-height: 18px;
  }
  .studio-contact-right-bar p,
  .studio-contact-right-bar span {
    font-size: 20px !important;
    line-height: 18px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  padding: 29px 141px 13px 50px;
}
.header-with-bg {
  background-color: #fff;
  border-bottom: 1px solid #cccccc;
}
.header-with-bg h3{
  color: #434b62 !important;
}
.team-header-logo h3 {
  font-family: Source Serif Pro;
  font-weight: 600;
  font-size: 50px;
  line-height: 62.65px;
  letter-spacing: 0.12em;
  color: #434b62;
}
@media (max-width: 1146px) {
  .header {
    padding: 44px 36px 41.8px 36px;
  }
}
@media (max-width: 749px) {
  .header {
    padding: 28px 36px 41px 36px;
  }

  .studio-jobs .header .header-logo h3,
  .studio-contact .header .header-logo h3 {
    color: #fff !important;
  }
}
@media (max-width: 716px) {
  .header {
    padding: 16.5px 22px 16.5px 30px;
  }
}
@media(max-width:880px){
  .studio-jobs .header-with-bg .menu-icon,
  .studio-contact .header-with-bg .menu-icon {
    background-color: black !important;
  }

  .studio-jobs .header .header-logo h3,
  .studio-contact .header .header-logo h3 {
    color: white !important;
  }

  .studio-jobs .header-with-bg .header-logo h3,
  .studio-contact .header-with-bg .header-logo h3 {
    color: #434b62 !important;
  }
}

.studio-jobs {
  position: relative;
}
.studio-jobs-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 29px 140px 0 50px;
}
.studio-jobs-content {
  display: grid;
  grid-template-columns: 50% 50%;
}
.studio-jobs-page {
  display: none;
}
.studio-jobs-left-bar {
  background-size: cover;
  background-repeat: no-repeat;
  height: 990px;
}

.jobs-header-logo {
  text-align: right;
  margin-right: 0 !important;
}
.studio-jobs-right-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 140px 0 92px;
}
.studio-jobs-right-bar h3 {
  font-family: var(--bold-font);
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.02em;
}
.lang-am .studio-jobs-right-bar h3{
  font-size: 14px !important;
}
.studio-jobs-right-bar p {
  font-family: var( --bac-font );
  font-weight: 400;
  font-size: 22px;
  line-height: 24px;
  letter-spacing: 0.02em;
  padding-top: 40px;
}
.lang-am .studio-jobs-right-bar p{
  font-size: 13px !important;
}
.footer-languages {
  display: none;
}
.studio-jobs-hide {
  height: 100vh;
  overflow: hidden;
}

@media (max-width: 880px) {
  .studio-jobs-header {
    padding: 28px 36px 20px 36px;
  }
  .studio-jobs-page {
    display: block;
  }
  .menu-icon {
    background-color: white !important;
  }
  .header-logo h3 {
    color: #fff;
  }
  .studio-jobs-content {
    display: grid;
    grid-template-columns: 1fr !important;
  }
  .studio-page-name {
    padding: 20px 0 25px 36px;
  }
  .studio-jobs-left-bar {
    height: 956px;
  }
  .studio-jobs-right-bar {
    padding: 25px 36px 0 36px;
  }
  .studio-jobs-right-bar h3 {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
  }
  .studio-jobs-right-bar p {
    font-weight: 400;
    font-size: 20px;
    line-height: 18px;
  }
}

@media (max-width: 425px) {
  .studio-jobs-header {
    padding: 33px 22px 19px 30px;
  }
  .menu-black-icon {
    background-color: black !important;
  }
  .header-logo h3 {
    color: #434B62;
  }
  .black-logo h3 {
    font-size: 35px !important;
    line-height: 44px !important;
  }

  .studio-jobs-left-bar {
    height: 475px;
  }

  .studio-jobs-right-bar {
    padding: 30px 20px 43px 20px;
  }
  .studio-jobs-right-bar h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 18px;
  }
  .studio-jobs-right-bar p {
    font-weight: 400;
    font-size: 20px;
    line-height: 18px;
  }
}

.studio-team-content {
  padding-top: 182px;
}

.studio-team-page-name {
  padding: 0 0 33px 11vw !important;
}
.studio-team-img {
  padding: 0 11vw;
}
.studio-team-img img {
  object-fit: cover;
  width: 100%;
}
.studio-about-team {
  grid-template-columns: 36fr 64fr;
  grid-column-gap: 56px;
  display: grid;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 11vw;
  margin-top: 56px;
}

@media screen and (max-width: 1100px) {
  .studio-about-team {
    display: flex !important;
    grid-template-columns: unset;
    grid-column-gap: unset;
  }
}

.studio-team-info {
  font-family: var(--bold-font);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  width: 27.5vw;
  -webkit-hyphens: auto;
          hyphens: auto;
}
.lang-am .studio-team-info {
  font-size: 12px !important;
  line-height: 17px !important;
}
.studio-team-description {
  font-family: var(--bac-font);
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: justify;
}
.lang-am .studio-team-description {
  font-size: 13px !important;
  line-height: 17px !important;
}

.studio-team-members {
  display: grid;
  grid-template-columns: repeat(3, -webkit-max-content);
  grid-template-columns: repeat(3, max-content);
  justify-content: space-between;
  padding: 0 11vw;
  margin-top: 10vh;
  position: relative;
}
.studio-team-member {
  position: relative;
  margin-bottom: 50px;
  width: 24vw;
  max-height: 300px;
  max-width: 480px;
}
.studio-team-member img {
  width: 100%;
  height: 100%;
}
.studio-team-member:hover .studio-team-member-info {
  position: absolute;
  display: flex;
}
.studio-team-member-info {
  display: none;
  flex-direction: column;
  justify-content: flex-end;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  padding: 0 38px 28px 26px;
  transition-duration: 300ms;
  transition-timing-function: ease-out;
}
.studio-team-member-info p {
  margin-top: 17px;
}
.member-name {
  font-family: Aharoni;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: #ffffff;
}

.lang-am .member-name {
  font-size: 14px;
}

.member-info {
  font-family: var(--bac-font);
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 27px;
  letter-spacing: 0.04em;
  color: #ffffff;
}

.lang-am .member-info {
  font-size: 13px;
}

.footer {
  display: flex;
  justify-content: center;
  padding: 132px 0 44px 0;
}
.projects .footer {
  flex: 1 1 auto;
}
.footer-social-media-icons img:not(:first-child) {
  margin-left: 1vw;
}
.studio-team-hide {
  overflow: hidden;
  height: 100vh;
}
@media (max-width: 1146px) {
  .studio-team-members {
    padding: 0 36px;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }
  .studio-team .menu-black-icon {
    background-color: black !important;
  }
  .studio-team .header-logo h3 {
    color: #434b62 !important;
  }
  .studio-team-member {
    width: 90%;
  }
  .studio-page-name {
    padding: 41px 0 30px 36px;
    font-size: 18px;
    line-height: 20px;
  }
  .studio-team-info {
    letter-spacing: 0em;
    width: 100%;
    padding-bottom: 20px;
  }
  .studio-team-description {
    width: 100%;
  }
  .studio-team-img {
    padding: 0;
  }
  .member-info {
    font-size: 22px;
  }
  .member-name {
    font-size: 16px;
  }
}
@media (max-width: 1100px) {
  .studio-team-info{
    font-size: 15px;
  }
}
@media (max-width: 880px) {
  .studio-team-info, .studio-team-description{
    line-height: 18px;
  }
  .studio-team-info{
    font-size: 13px;
  }
  .studio-team-description{
    font-size: 20px;
  }
}
@media (max-width: 749px) {
  .studio-team-info p {
    font-size: 22px;
    line-height: 22px;
  }

  .studio-team-members {
    padding: 0 22px;
  }
  .member-info {
    font-size: 20px;
  }
  .member-name {
    font-size: 18px;
  }
  .studio-page-name {
    padding: 41px 0 25px 36px;
  }

  .language-icon {
    width: 24px;
    height: 24px;
  }
}
@media (max-width: 716px) {
  .studio-team-members {
    grid-template-columns: 1fr;
  }
  .studio-team-info p {
    font-size: 19px;
    line-height: 17px;
  }
  .studio-team-member {
    width: 60%;
  }
  .studio-page-name {
    display: none;
  }
}
@media (max-width: 425px) {
  .studio-team-member {
    width: 88%;
  }
  .studio-team-info {
    word-spacing: -1px;
  }
}

.selected-projects {
  animation-name: selected-anim;
  animation-duration: 1s;
}
@keyframes selected-anim {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.projects > div {
  min-height: 100vh;
  min-width: 100%;
  display: flex;
  flex-direction: column;
}
.selected-projects-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 29px 141px 35.4px 50px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.selected-projects-content {
  margin: 151px auto;
}
.projects-container {
  display: grid;
  grid-template-columns: repeat(4, 320px);
  grid-column-gap: 60px;
}

@media screen and (max-width: 1580px) {
  .projects-container {
    display: grid;
    grid-template-columns: repeat(4, 300px);
    grid-column-gap: 50px;
  }
}

@media screen and (max-width: 1400px) {
  .projects-container {
    display: grid;
    grid-template-columns: repeat(4, 270px);
    grid-column-gap: 40px;
  }
}

.black-logo h3 {
  font-family: Source Serif Pro;
  font-weight: 600;
  font-size: 50px;
  line-height: 63px;
  letter-spacing: 0.12em;
  color: #434b62;
}
.footer-languages {
  display: block;
}
.page-name {
  padding-bottom: 64px;
  font-family: var(--bac-font);
  font-weight: 400;
  font-size: 22px;
  line-height: 25px;
}

.lang-am .page-name {
  font-size: 13px !important;
}

.lang-am .page-name-bold-text {
  font-size: 1em;
}

.page-name-bold-text {
  font-size: 0.8em;
  font-weight: 700;
  font-family: var(--bold-font);
}

.menu-black-icon {
  background-color: black !important;
  background-size: cover;
  background-repeat: no-repeat !important;
  width: 20px;
  height: 26px;
  cursor: pointer;
}

.studio-contact .menu-black-icon,
.studio-jobs .menu-black-icon,
.project-page .menu-black-icon {
  background-color: black !important;
}

.header-with-bg .menu-black-icon {
  background-color: black !important;
}
.arrow {
  padding: 0 5px;
}
.project {
  cursor: pointer;
  padding-bottom: 94px;
  padding-right: 20px;
}

.project img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.project:hover {
  opacity: 0.8;
}
.project p {
  font-family: var(--bac-font);
  font-size: 22px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #000000;
  padding-top: 10px;
}

.lang-am .project p {
  font-size: 12px;
  line-height: 20px;
}

.project-footer {
  margin-top: 0 !important;
}
.projects-footer {
  display: flex;
  justify-content: center;
  padding: 0 0 85px 0;
}

.selected-projects-hide {
  overflow: hidden;
  height: 100vh;
}
@media (max-width: 1280px) {
  .projects-container {
    grid-template-columns: repeat(3, 26vw);
    grid-column-gap: 2.6vw;
  }
}
@media (max-width: 852px) {
  .selected-projects-header {
    padding: 28px 36px 42px 36px;
  }
  .menu-black-icon {
    background-color: black;
  }
  .projects-container {
    grid-template-columns: repeat(2, 44vw);
    grid-column-gap: 2.6vw;
  }
  .header-logo h3 {
    color: #434b62 !important;
  }

  .studio-contact .header-logo h3 {
    color: white !important;
  }
  .selected-projects-content {
    margin-top: 137px;
    margin-left: unset;
  }
  .projects {
    padding: 0 5vw;
  }
  .project {
    margin-bottom: 40px;
  }
  .project p {
    font-size: 18px !important;
    font-size: 18px !important;
    line-height: 29px !important;
  }
  .page-name {
    padding: 0 0 44px;
  }
}
@media (max-width: 752px) {
  .selected-projects-header {
    padding: 33.3px 22px 20px 30px;
  }
  .menu-black-icon {
    width: 23px;
    height: 28px;
    background-color: black;
  }
  .selected-projects-content {
    margin-top: 115px;
  }
  .project {
    padding-bottom: 30px;
  }
  .project p {
    font-size: 18px !important;
    line-height: 18px !important;
  }

  .lang-am .project p {
    font-size: 14px !important;
    line-height: 14px !important;
  }

  .header-logo h3 {
    padding: unset !important;
  }
  .page-name {
    font-size: 12px !important;
    line-height: 12px !important;
  }

  .page-name {
    padding: 0 0 37px;
  }
}
@media (max-width: 425px) {
  .selected-projects-content {
    margin-left: unset;
    margin-right: unset;
  }
  .projects-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: unset;
  }
  .project {
    padding-right: unset;
  }
  .project p {
    font-size: 14px;
  }
  .selected-projects-content  {
    padding: 0 0 37px;
  }
}

@media screen and (max-width: 770px) {
  .lang-eng .page-name,
  .lang-eng .project p {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 425px) {
  .lang-eng .page-name {
    font-size: 14px !important;
  }

  .lang-eng .project p {
    font-size: 14px !important;
  }
}

.in-progress-projects-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 29px 30px 0 30px;
}
.in-progress-projects-hide {
  overflow-y: hidden;
  height: 100vh;
}
.in-progres-projects-content {
  margin: 151px 50px;
}

@media screen and (max-width: 852px) {
  .in-progres-projects-content {
    margin: 151px 0;
  }
}
.project-page {
  position: relative;
}
.project-header-hide {
  height: 100vh;
  overflow: hidden;
}
.project-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 51.5px 7.3vw 0 2.6vw;
  position: fixed;
  top: 0;
  left: 0;
}
.project-left-bar img {
  width: 50vw;
  height: 101vh;
  object-fit: cover;
}
.project-image-name {
  display: flex;
  align-items: center;
}
.project-name {
  margin: auto;
  cursor: pointer;
  text-align: center;
  animation-name: project-name-anim;
  animation-duration: 1.5s;
}
.project-name img {
  animation-name: project-name-img-anim;
  animation-delay: 500ms;
  animation-duration: 700ms;
  animation-fill-mode: forwards;
}
.project-name a {
  color: #000;
}
.project-name h3 {
  font-family: var(--bac-font);
  font-weight: 400;
  font-size: 48px;
  line-height: 54px;
  letter-spacing: 0.05em;
}
.lang-am .project-name h3 {
  font-size: 30px;
}
.project-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 56px 172px;
}
@media (max-width: 880px) {
  .project-content {
    display: flex;
    grid-template-columns: unset;
  }
}

.project-content-location-wrapper {
  display: flex;
  justify-content: flex-end;
}
.project-content-location {
  font-family: var(--bac-font);
  font-size: 23px;
  line-height: 27px;
  margin-right: 110px;
  font-weight: 400;
}
@media screen and (max-width: 800px) {
  .project-info-header {
    font-size: 14px !important;
  }
}

.project-content-about {
  font-size: 24px;
  font-family: var(--bac-font);
  font-weight: 400;
  text-align: justify;
}
.lang-am .project-content-about {
  font-size: 14px !important;
}
.lang-am .project-info-number{
  font-size: 13px;
}
.project-info-header {
  font-family: var(--bold-font);
  font-weight: 700;
  font-size: 16px;
}
.lang-am .project-info-header,
.lang-am .project-content-location {
  font-size: 13px !important;
}
.project-images {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 18vw;
}
.project-images > div {
  width: 98vw;
}
.project-images img:not(:nth-of-type(1)) {
  margin-top: 100px;
}
.project-images div {
  margin-top: 100px;
}
.project-images img {
  max-width: 100%;
}
.back {
  border-top: 1px solid #cbcbcb;
  border-bottom: 1px solid #cbcbcb;
  font-weight: 300;
  font-family: var(--bac-font);
  font-size: 20px;
  line-height: 22px;
  color: #525252;
  letter-spacing: 0.05em;
  padding: 30px 0;
  width: 80vw;
  text-align: center;
  margin-top: 150px;
  cursor: pointer;
}
.paralax-image {
  width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 779px;
}
@media (max-width: 880px) {
  .project-header {
    padding: 44px 36px 0 36px;
  }
  .menu-black-icon {
    background-color: black !important;
  }

  .project-page .header-logo h3 {
    color: #434b62 !important;
  }
  .project-left-bar {
    width: 100%;
  }
  .project-image-name {
    display: flex;
    flex-direction: column;
  }
  .project-name h3 {
    font-family: var(--bac-font);
    font-size: 40px;
    font-weight: bold;
    line-height: 35px;
    letter-spacing: 0.05em;
    padding-top: 28px;
  }
  .project-left-bar img {
    width: 100%;
  }
  .project-right-bar {
    padding: 25px 36px 0 36px;
  }
  .project-content {
    flex-wrap: wrap;
    padding: 30px 35px 50px 35px;
    justify-content: flex-start;
  }
  .lang-eng .project-content-location,
  .lang-eng.project-content-about p {
    font-size: 20px;
    line-height: 22px;
  }
  .project-content-about {
    margin-top: 30px;
    width: 100%;
  }
  .project-images {
    padding: 0 40px;
  }
  .back {
    margin-top: 120px;
    padding: 20px 0;
  }
  .paralax-image {
    height: 410px !important;
    background-attachment: unset;
    width: 100% !important;
  }
}
@media (max-width: 425px) {
  .project-header {
    padding: 33px 22px 19px 30px;
  }
  .project-name h3 {
    font-size: 32px;
    line-height: 25px;
    padding-top: 60px;
  }

  .black-logo h3 {
    font-size: 35px !important;
    line-height: 44px !important;
  }
  .project-content {
    padding: 96px 18px 40px 18px;
  }
  .project-images {
    padding: 0 20px;
  }
  .back {
    margin-top: 60px;
    margin-bottom: 110px;
    padding: 19px 0;
  }
  .footer {
    padding: 0 4vw 3vh 4.2vw !important;
  }
  .paralax-image {
    height: 200px !important;
  }
}
@keyframes project-name-anim {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes project-name-img-anim {
  from {
    margin-top: 0;
  }
  to {
    margin-top: 30px;
  }
}

