.studio-contact {
  position: relative;
}
.studio-contact-hide {
  height: 100vh;
  overflow: hidden;
}
.studio-contact-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 29px 140px 0 50px;
}
.studio-contact-content {
  display: grid;
  grid-template-columns: 50% 50%;
}
.studio-contact-page {
  display: none;
}
.studio-contact-left-bar {
  background-size: cover;
  height: 990px;
}
.studio-contact-right-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 140px 0 92px;
}
.contact-address {
  width: 12vw;
}

.lang-eng .contact-address {
  font-family: var(--bac-font);
  font-size: 21px;
}

.contact-header-logo {
  text-align: right;
  margin-right: 0 !important;
}
.contact-info-header {
  font-family: var(--bac-font);
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
}
.lang-am .contact-info-header {
  font-size: 14px !important;
}
.contact-right-bar-content p {
  font-family: var(--bac-font);
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
}
.lang-am .studio-contact-right-bar p {
  font-size: 13px !important;
}
.contact-menu-icon {
  margin: 2vw 0 0 2vw;
}
.contact-email {
  color: #000;
  height: 22px;
  display: inline-block;
}

.lang-eng .contact-email {
  font-family: var(--bac-font);
  font-size: 21px;
}
.lang-am .contact-email{
  font-size: 16px;
}

.contact-info-email {
  margin-top: 2vh;
}
@media (max-width: 880px) {
  .studio-contact-header {
    padding: 41.8px 0 20px 36px;
  }
  .menu-icon {
    background-color: white !important;
  }
  .header-logo h3 {
    color: #fff !important;
  }
  .studio-contact-content {
    display: grid;
    grid-template-columns: 1fr !important;
  }
  .studio-page-name {
    padding: 20px 0 25px 36px;
  }
  .studio-contact-left-bar {
    height: 805px;
  }
  .studio-contact-right-bar {
    padding: 25px 36px 0 36px;
  }
  .studio-contact-right-bar h3 {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
  }
  .studio-contact-right-bar p,
  .studio-contact-right-bar span,
  .studio-contact-right-bar a {
    font-size: 18px !important;
    line-height: 18px;
  }
  .contact-address {
    width: 270px;
  }
}
@media (max-width: 425px) {
  .studio-contact-header {
    padding: 33px 22px 19px 30px;
  }
  .menu-black-icon {
    background-color: black !important;
  }
  .header-logo h3 {
    color: #434b62 !important;
  }
  .black-logo h3 {
    font-size: 35px !important;
    line-height: 44px !important;
  }

  .studio-contact-page {
    display: none;
  }
  .studio-contact-left-bar {
    height: 475px;
    margin-top: 0;
  }

  .studio-contact-right-bar {
    padding: 30px 20px 43px 20px;
  }
  .studio-contact-right-bar h3 {
    font-weight: 700;
    font-size: 14px !important;
    line-height: 18px;
  }
  .studio-contact-right-bar p,
  .studio-contact-right-bar span {
    font-size: 20px !important;
    line-height: 18px;
  }
}
