.selected-projects {
  animation-name: selected-anim;
  animation-duration: 1s;
}
@keyframes selected-anim {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.projects > div {
  min-height: 100vh;
  min-width: 100%;
  display: flex;
  flex-direction: column;
}
.selected-projects-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 29px 141px 35.4px 50px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.selected-projects-content {
  margin: 151px auto;
}
.projects-container {
  display: grid;
  grid-template-columns: repeat(4, 320px);
  grid-column-gap: 60px;
}

@media screen and (max-width: 1580px) {
  .projects-container {
    display: grid;
    grid-template-columns: repeat(4, 300px);
    grid-column-gap: 50px;
  }
}

@media screen and (max-width: 1400px) {
  .projects-container {
    display: grid;
    grid-template-columns: repeat(4, 270px);
    grid-column-gap: 40px;
  }
}

.black-logo h3 {
  font-family: Source Serif Pro;
  font-weight: 600;
  font-size: 50px;
  line-height: 63px;
  letter-spacing: 0.12em;
  color: #434b62;
}
.footer-languages {
  display: block;
}
.page-name {
  padding-bottom: 64px;
  font-family: var(--bac-font);
  font-weight: 400;
  font-size: 22px;
  line-height: 25px;
}

.lang-am .page-name {
  font-size: 13px !important;
}

.lang-am .page-name-bold-text {
  font-size: 1em;
}

.page-name-bold-text {
  font-size: 0.8em;
  font-weight: 700;
  font-family: var(--bold-font);
}

.menu-black-icon {
  background-color: black !important;
  background-size: cover;
  background-repeat: no-repeat !important;
  width: 20px;
  height: 26px;
  cursor: pointer;
}

.studio-contact .menu-black-icon,
.studio-jobs .menu-black-icon,
.project-page .menu-black-icon {
  background-color: black !important;
}

.header-with-bg .menu-black-icon {
  background-color: black !important;
}
.arrow {
  padding: 0 5px;
}
.project {
  cursor: pointer;
  padding-bottom: 94px;
  padding-right: 20px;
}

.project img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.project:hover {
  opacity: 0.8;
}
.project p {
  font-family: var(--bac-font);
  font-size: 22px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #000000;
  padding-top: 10px;
}

.lang-am .project p {
  font-size: 12px;
  line-height: 20px;
}

.project-footer {
  margin-top: 0 !important;
}
.projects-footer {
  display: flex;
  justify-content: center;
  padding: 0 0 85px 0;
}

.selected-projects-hide {
  overflow: hidden;
  height: 100vh;
}
@media (max-width: 1280px) {
  .projects-container {
    grid-template-columns: repeat(3, 26vw);
    grid-column-gap: 2.6vw;
  }
}
@media (max-width: 852px) {
  .selected-projects-header {
    padding: 28px 36px 42px 36px;
  }
  .menu-black-icon {
    background-color: black;
  }
  .projects-container {
    grid-template-columns: repeat(2, 44vw);
    grid-column-gap: 2.6vw;
  }
  .header-logo h3 {
    color: #434b62 !important;
  }

  .studio-contact .header-logo h3 {
    color: white !important;
  }
  .selected-projects-content {
    margin-top: 137px;
    margin-left: unset;
  }
  .projects {
    padding: 0 5vw;
  }
  .project {
    margin-bottom: 40px;
  }
  .project p {
    font-size: 18px !important;
    font-size: 18px !important;
    line-height: 29px !important;
  }
  .page-name {
    padding: 0 0 44px;
  }
}
@media (max-width: 752px) {
  .selected-projects-header {
    padding: 33.3px 22px 20px 30px;
  }
  .menu-black-icon {
    width: 23px;
    height: 28px;
    background-color: black;
  }
  .selected-projects-content {
    margin-top: 115px;
  }
  .project {
    padding-bottom: 30px;
  }
  .project p {
    font-size: 18px !important;
    line-height: 18px !important;
  }

  .lang-am .project p {
    font-size: 14px !important;
    line-height: 14px !important;
  }

  .header-logo h3 {
    padding: unset !important;
  }
  .page-name {
    font-size: 12px !important;
    line-height: 12px !important;
  }

  .page-name {
    padding: 0 0 37px;
  }
}
@media (max-width: 425px) {
  .selected-projects-content {
    margin-left: unset;
    margin-right: unset;
  }
  .projects-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: unset;
  }
  .project {
    padding-right: unset;
  }
  .project p {
    font-size: 14px;
  }
  .selected-projects-content  {
    padding: 0 0 37px;
  }
}

@media screen and (max-width: 770px) {
  .lang-eng .page-name,
  .lang-eng .project p {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 425px) {
  .lang-eng .page-name {
    font-size: 14px !important;
  }

  .lang-eng .project p {
    font-size: 14px !important;
  }
}
