.studio-team-content {
  padding-top: 182px;
}

.studio-team-page-name {
  padding: 0 0 33px 11vw !important;
}
.studio-team-img {
  padding: 0 11vw;
}
.studio-team-img img {
  object-fit: cover;
  width: 100%;
}
.studio-about-team {
  grid-template-columns: 36fr 64fr;
  grid-column-gap: 56px;
  display: grid;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 11vw;
  margin-top: 56px;
}

@media screen and (max-width: 1100px) {
  .studio-about-team {
    display: flex !important;
    grid-template-columns: unset;
    grid-column-gap: unset;
  }
}

.studio-team-info {
  font-family: var(--bold-font);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  width: 27.5vw;
  hyphens: auto;
}
.lang-am .studio-team-info {
  font-size: 12px !important;
  line-height: 17px !important;
}
.studio-team-description {
  font-family: var(--bac-font);
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: justify;
}
.lang-am .studio-team-description {
  font-size: 13px !important;
  line-height: 17px !important;
}

.studio-team-members {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  justify-content: space-between;
  padding: 0 11vw;
  margin-top: 10vh;
  position: relative;
}
.studio-team-member {
  position: relative;
  margin-bottom: 50px;
  width: 24vw;
  max-height: 300px;
  max-width: 480px;
}
.studio-team-member img {
  width: 100%;
  height: 100%;
}
.studio-team-member:hover .studio-team-member-info {
  position: absolute;
  display: flex;
}
.studio-team-member-info {
  display: none;
  flex-direction: column;
  justify-content: flex-end;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  padding: 0 38px 28px 26px;
  transition-duration: 300ms;
  transition-timing-function: ease-out;
}
.studio-team-member-info p {
  margin-top: 17px;
}
.member-name {
  font-family: Aharoni;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: #ffffff;
}

.lang-am .member-name {
  font-size: 14px;
}

.member-info {
  font-family: var(--bac-font);
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 27px;
  letter-spacing: 0.04em;
  color: #ffffff;
}

.lang-am .member-info {
  font-size: 13px;
}

.footer {
  display: flex;
  justify-content: center;
  padding: 132px 0 44px 0;
}
.projects .footer {
  flex: 1 1 auto;
}
.footer-social-media-icons img:not(:first-child) {
  margin-left: 1vw;
}
.studio-team-hide {
  overflow: hidden;
  height: 100vh;
}
@media (max-width: 1146px) {
  .studio-team-members {
    padding: 0 36px;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }
  .studio-team .menu-black-icon {
    background-color: black !important;
  }
  .studio-team .header-logo h3 {
    color: #434b62 !important;
  }
  .studio-team-member {
    width: 90%;
  }
  .studio-page-name {
    padding: 41px 0 30px 36px;
    font-size: 18px;
    line-height: 20px;
  }
  .studio-team-info {
    letter-spacing: 0em;
    width: 100%;
    padding-bottom: 20px;
  }
  .studio-team-description {
    width: 100%;
  }
  .studio-team-img {
    padding: 0;
  }
  .member-info {
    font-size: 22px;
  }
  .member-name {
    font-size: 16px;
  }
}
@media (max-width: 1100px) {
  .studio-team-info{
    font-size: 15px;
  }
}
@media (max-width: 880px) {
  .studio-team-info, .studio-team-description{
    line-height: 18px;
  }
  .studio-team-info{
    font-size: 13px;
  }
  .studio-team-description{
    font-size: 20px;
  }
}
@media (max-width: 749px) {
  .studio-team-info p {
    font-size: 22px;
    line-height: 22px;
  }

  .studio-team-members {
    padding: 0 22px;
  }
  .member-info {
    font-size: 20px;
  }
  .member-name {
    font-size: 18px;
  }
  .studio-page-name {
    padding: 41px 0 25px 36px;
  }

  .language-icon {
    width: 24px;
    height: 24px;
  }
}
@media (max-width: 716px) {
  .studio-team-members {
    grid-template-columns: 1fr;
  }
  .studio-team-info p {
    font-size: 19px;
    line-height: 17px;
  }
  .studio-team-member {
    width: 60%;
  }
  .studio-page-name {
    display: none;
  }
}
@media (max-width: 425px) {
  .studio-team-member {
    width: 88%;
  }
  .studio-team-info {
    word-spacing: -1px;
  }
}
