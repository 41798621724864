.project-page {
  position: relative;
}
.project-header-hide {
  height: 100vh;
  overflow: hidden;
}
.project-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 51.5px 7.3vw 0 2.6vw;
  position: fixed;
  top: 0;
  left: 0;
}
.project-left-bar img {
  width: 50vw;
  height: 101vh;
  object-fit: cover;
}
.project-image-name {
  display: flex;
  align-items: center;
}
.project-name {
  margin: auto;
  cursor: pointer;
  text-align: center;
  animation-name: project-name-anim;
  animation-duration: 1.5s;
}
.project-name img {
  animation-name: project-name-img-anim;
  animation-delay: 500ms;
  animation-duration: 700ms;
  animation-fill-mode: forwards;
}
.project-name a {
  color: #000;
}
.project-name h3 {
  font-family: var(--bac-font);
  font-weight: 400;
  font-size: 48px;
  line-height: 54px;
  letter-spacing: 0.05em;
}
.lang-am .project-name h3 {
  font-size: 30px;
}
.project-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 56px 172px;
}
@media (max-width: 880px) {
  .project-content {
    display: flex;
    grid-template-columns: unset;
  }
}

.project-content-location-wrapper {
  display: flex;
  justify-content: flex-end;
}
.project-content-location {
  font-family: var(--bac-font);
  font-size: 23px;
  line-height: 27px;
  margin-right: 110px;
  font-weight: 400;
}
@media screen and (max-width: 800px) {
  .project-info-header {
    font-size: 14px !important;
  }
}

.project-content-about {
  font-size: 24px;
  font-family: var(--bac-font);
  font-weight: 400;
  text-align: justify;
}
.lang-am .project-content-about {
  font-size: 14px !important;
}
.lang-am .project-info-number{
  font-size: 13px;
}
.project-info-header {
  font-family: var(--bold-font);
  font-weight: 700;
  font-size: 16px;
}
.lang-am .project-info-header,
.lang-am .project-content-location {
  font-size: 13px !important;
}
.project-images {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 18vw;
}
.project-images > div {
  width: 98vw;
}
.project-images img:not(:nth-of-type(1)) {
  margin-top: 100px;
}
.project-images div {
  margin-top: 100px;
}
.project-images img {
  max-width: 100%;
}
.back {
  border-top: 1px solid #cbcbcb;
  border-bottom: 1px solid #cbcbcb;
  font-weight: 300;
  font-family: var(--bac-font);
  font-size: 20px;
  line-height: 22px;
  color: #525252;
  letter-spacing: 0.05em;
  padding: 30px 0;
  width: 80vw;
  text-align: center;
  margin-top: 150px;
  cursor: pointer;
}
.paralax-image {
  width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 779px;
}
@media (max-width: 880px) {
  .project-header {
    padding: 44px 36px 0 36px;
  }
  .menu-black-icon {
    background-color: black !important;
  }

  .project-page .header-logo h3 {
    color: #434b62 !important;
  }
  .project-left-bar {
    width: 100%;
  }
  .project-image-name {
    display: flex;
    flex-direction: column;
  }
  .project-name h3 {
    font-family: var(--bac-font);
    font-size: 40px;
    font-weight: bold;
    line-height: 35px;
    letter-spacing: 0.05em;
    padding-top: 28px;
  }
  .project-left-bar img {
    width: 100%;
  }
  .project-right-bar {
    padding: 25px 36px 0 36px;
  }
  .project-content {
    flex-wrap: wrap;
    padding: 30px 35px 50px 35px;
    justify-content: flex-start;
  }
  .lang-eng .project-content-location,
  .lang-eng.project-content-about p {
    font-size: 20px;
    line-height: 22px;
  }
  .project-content-about {
    margin-top: 30px;
    width: 100%;
  }
  .project-images {
    padding: 0 40px;
  }
  .back {
    margin-top: 120px;
    padding: 20px 0;
  }
  .paralax-image {
    height: 410px !important;
    background-attachment: unset;
    width: 100% !important;
  }
}
@media (max-width: 425px) {
  .project-header {
    padding: 33px 22px 19px 30px;
  }
  .project-name h3 {
    font-size: 32px;
    line-height: 25px;
    padding-top: 60px;
  }

  .black-logo h3 {
    font-size: 35px !important;
    line-height: 44px !important;
  }
  .project-content {
    padding: 96px 18px 40px 18px;
  }
  .project-images {
    padding: 0 20px;
  }
  .back {
    margin-top: 60px;
    margin-bottom: 110px;
    padding: 19px 0;
  }
  .footer {
    padding: 0 4vw 3vh 4.2vw !important;
  }
  .paralax-image {
    height: 200px !important;
  }
}
@keyframes project-name-anim {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes project-name-img-anim {
  from {
    margin-top: 0;
  }
  to {
    margin-top: 30px;
  }
}
