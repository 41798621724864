.footer-social-media-icons {
  cursor: pointer;
  padding-right: 20px;
  align-self: flex-end;
}
.footer-social-media-icons a:not(:last-child){
  margin-right: 20px;
}
.footer-languages {
  margin-left: 1vw;
  padding-left: 20px;
  border-left: 1px solid rgba(94, 94, 94, 0.5);
  cursor: pointer;
}
.footer-languages ul {
  list-style: none;
  position: relative;
}
.footer-languages ul li {
  display: flex;
  align-items: center;
  background: #f9f9f9;
}
.footer-languages ul li p {
  margin-left: 0.5vw;
  font-family: var( --bac-font );
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.05em;
  color: #5d5d5d;
}
.hidden-languages {
  margin-bottom: 5px;
  position: absolute;
  top: -30px;
}
@media (max-width: 749px) {
  .language-icon {
    width: 24px;
    height: 24px;
  }
}
