.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  padding: 29px 141px 13px 50px;
}
.header-with-bg {
  background-color: #fff;
  border-bottom: 1px solid #cccccc;
}
.header-with-bg h3{
  color: #434b62 !important;
}
.team-header-logo h3 {
  font-family: Source Serif Pro;
  font-weight: 600;
  font-size: 50px;
  line-height: 62.65px;
  letter-spacing: 0.12em;
  color: #434b62;
}
@media (max-width: 1146px) {
  .header {
    padding: 44px 36px 41.8px 36px;
  }
}
@media (max-width: 749px) {
  .header {
    padding: 28px 36px 41px 36px;
  }

  .studio-jobs .header .header-logo h3,
  .studio-contact .header .header-logo h3 {
    color: #fff !important;
  }
}
@media (max-width: 716px) {
  .header {
    padding: 16.5px 22px 16.5px 30px;
  }
}
@media(max-width:880px){
  .studio-jobs .header-with-bg .menu-icon,
  .studio-contact .header-with-bg .menu-icon {
    background-color: black !important;
  }

  .studio-jobs .header .header-logo h3,
  .studio-contact .header .header-logo h3 {
    color: white !important;
  }

  .studio-jobs .header-with-bg .header-logo h3,
  .studio-contact .header-with-bg .header-logo h3 {
    color: #434b62 !important;
  }
}
