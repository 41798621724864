.home-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 45px 141px 0 50px;
}
.header-logo-text {
  font-family: Source Serif Pro;
  font-weight: 600;
  font-size: 50px;
  line-height: 63px;
  letter-spacing: 0.12em;
  color: #fff;
}
.header-logo-black-text {
  font-family: Source Serif Pro;
  font-weight: 600;
  font-size: 50px;
  line-height: 63px;
  letter-spacing: 0.12em;
  color: #434b62;
}
.close-icon,
.social-media-icons img,
.menu-icon {
  cursor: pointer;
}

.close-icon {
  width: 20px;
  height: 11px;
  mask-image: url(../../images/close-button.svg);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: cover;
  background-color: black;
}

.menu-icon {
  background-color: black !important;
  background-size: contain;
  background-repeat: no-repeat;
  width: 30px;
  height: 42px;
}
.awssld {
  z-index: -10;
  --loader-bar-height: 0px;
}
.awssld__timer,
.awssld__timer--run {
  opacity: 0;
  height: 0 !important;
}
.awssld__bullets {
  z-index: 10;
  bottom: 35.5px;
}
.awssld__bullets button {
  width: 14px;
  height: 14px;
  background: rgba(203, 203, 203, 0.59);
  transform: scale(1);
}
.awssld__bullets--active {
  background-color: #fff !important;
}
.awssld__bullets .awssld__bullets--active {
  transform: scale(1.2);
}
.menu {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  display: grid;
  grid-template-rows: auto 1fr;
  z-index: 11;
  animation-name: menu-anim;
}
@keyframes menu-anim {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.menu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 35px 93px 0 50px;
}
.menu-logo h3 {
  font-family: Source Serif Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 63px;
  letter-spacing: 0.12em;
  color: #434b62;
}

.logo-image {
  width: 200px;
  height: 62px;
  object-fit: cover;
}

.white-logo-image {
  background-color: black !important;
  width: 200px;
  height: 62px;
  -webkit-mask-image: url(../../images/anarch-logo-black.svg);
  mask-image: url(../../images/anarch-logo-black.svg);
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-position: center;
  mask-position: center;
}

.menu-pageheader {
  font-family: var(--bold-font);
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  color: #000000;
}
.menu-page dt {
  margin-bottom: 10px;
}
.pages a {
  font-family: var(--bac-font);
  font-weight: normal;
  line-height: 28px;
  color: #000;
}
.menu-page:not(:first-child) {
  margin-left: 4vw;
  width: 10vw;
}
.menu-container {
  margin: auto;
  display: grid;
  grid-template-rows: repeat(2, max-content);
  align-items: center;
  max-height: -webkit-fill-available;
  overflow: auto;
}
.menu-columns {
  width: 100%;
}
.menu-item-titles {
  display: grid;
  grid-template-columns: 3.2vw 8vw 8vw 9vw 8vw;
  grid-column-gap: 6.7vw;
  align-items: flex-end;
  margin-bottom: 10px;
}

.menu-items {
  display: grid;
  grid-template-columns: 3.2vw 8vw 8vw 9vw 8vw;
  grid-column-gap: 6.7vw;
}

@media screen and (max-width: 1620px) {
  .menu-item-titles {
    grid-template-columns: 3.2vw 8.8vw 9vw 10vw 8.8vw;
  }
  .menu-items {
    grid-template-columns: 3.2vw 8.8vw 9vw 10vw 8.8vw;
  }
}

@media screen and (max-width: 1460px) {
  .menu-item-titles {
    grid-template-columns: 5.2vw 10.8vw 10.9vw 12.4vw 10.7vw;
  }
  .menu-items {
    grid-template-columns: 5.2vw 10.8vw 10.9vw 12.4vw 10.7vw;
  }
}

@media screen and (max-width: 1200px) {
  .menu-item-titles {
    grid-template-columns: 5.2vw 14.4vw 14.4vw 14.4vw 14.4vw;
  }
  .menu-items {
    grid-template-columns: 5.2vw 14.4vw 14.4vw 14.4vw 14.4vw;
  }
}

.lang-am .menu-item-titles {
  grid-template-columns:
    7vw 11vw 11vw var(--menu-4th-column-width)
    var(--menu-4th-5th-column-width);
}
.lang-am .menu-items {
  grid-template-columns:
    7vw 11vw 11vw var(--menu-4th-column-width)
    var(--menu-4th-5th-column-width);
}
.menu-items > div a {
  font-family: var(--bac-font);
  font-size: 25px;
  font-style: normal;
  font-weight: normal;
  line-height: 28px;
  color: #000;
}

.lang-am:not(.App) {
  font-size: 14px;
  padding: 0 10vw;
}
.menu-item-title {
  font-family: var(--bold-font);
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
  color: #000000;
}
.lang-am .menu-item-title {
  font-size: 14px !important;
}
.lang-am .menu-items > div a {
  font-size: 13px !important;
}

.menu-footer-languages p {
  font-family: var(--bac-font);
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: #000000;
  cursor: pointer;
}
.lang-am .menu-footer-languages p {
  font-size: 13px;
}
.lang-am .active-language {
  font-size: 12px !important;
}
.active-language {
  font-family: var(--bold-font) !important;
  font-weight: bold !important;
  letter-spacing: 0.05em !important;
}
.menu-footer {
  margin-top: 10vh;
}
.social-media-icons {
  margin-bottom: 15px;
}
.instagram-icon {
  margin-left: 10px;
}

@media (max-width: 1050px) {
  .menu-item-title {
    font-size: 1.5vw;
  }
}

@media (max-width: 881px) {
  .home-header {
    padding: 30px 40px !important;
  }
  .menu-header {
    padding: 40px 30px !important;
  }
  .home-page .menu-icon {
    background-color: black !important;
  }
  .home-page .header-logo h3 {
    color: #fff !important;
  }

  .logo-image{
    width: 196px;
    height: 61px;
  }

  .menu-logo h3 {
    font-size: 35px !important;
    line-height: 44px !important;
  }
  .menu-container {
    margin: unset !important;
  }
  .pages {
    flex-direction: column;
    padding-left: 10vw;
  }
  .menu-page {
    width: unset !important;
    margin: 10px 0 !important;
  }
  .lang-am .menu-pageheader{
    font-size: 14px !important;
  }
  .menu-pageheader {
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.05em;
  }
  .lang-am .pages a{
    font-size: 13px !important;
  }
  .menu-footer {
    margin-top: 0 !important;
    padding-top: 40px;
    padding-left: 10vw;
    padding: 40px 0 159px 10vw;
  }
  .active-language {
    font-size: 22px;
    line-height: 25px;
    letter-spacing: 0.05em;
  }
  .menu-footer-languages p {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
  }
}

@media (max-width: 425px) {
  .home-header {
    padding: 33px 0 0 30px !important;
  }
  .home-header-logo h3 {
    padding: 0 20px;
  }
  .menu-page-header {
    font-family: Source Serif Pro;
    font-size: 16px;
    line-height: 20px;
  }
  .menu-pageheader {
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.05em;
  }
  .lang-eng .pages dd {
    font-size: 20px;
  }
  .pages dd {
    line-height: 22.59px;
    letter-spacing: 0.05em;
  }
}
