.studio-jobs {
  position: relative;
}
.studio-jobs-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 29px 140px 0 50px;
}
.studio-jobs-content {
  display: grid;
  grid-template-columns: 50% 50%;
}
.studio-jobs-page {
  display: none;
}
.studio-jobs-left-bar {
  background-size: cover;
  background-repeat: no-repeat;
  height: 990px;
}

.jobs-header-logo {
  text-align: right;
  margin-right: 0 !important;
}
.studio-jobs-right-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 140px 0 92px;
}
.studio-jobs-right-bar h3 {
  font-family: var(--bold-font);
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.02em;
}
.lang-am .studio-jobs-right-bar h3{
  font-size: 14px !important;
}
.studio-jobs-right-bar p {
  font-family: var( --bac-font );
  font-weight: 400;
  font-size: 22px;
  line-height: 24px;
  letter-spacing: 0.02em;
  padding-top: 40px;
}
.lang-am .studio-jobs-right-bar p{
  font-size: 13px !important;
}
.footer-languages {
  display: none;
}
.studio-jobs-hide {
  height: 100vh;
  overflow: hidden;
}

@media (max-width: 880px) {
  .studio-jobs-header {
    padding: 28px 36px 20px 36px;
  }
  .studio-jobs-page {
    display: block;
  }
  .menu-icon {
    background-color: white !important;
  }
  .header-logo h3 {
    color: #fff;
  }
  .studio-jobs-content {
    display: grid;
    grid-template-columns: 1fr !important;
  }
  .studio-page-name {
    padding: 20px 0 25px 36px;
  }
  .studio-jobs-left-bar {
    height: 956px;
  }
  .studio-jobs-right-bar {
    padding: 25px 36px 0 36px;
  }
  .studio-jobs-right-bar h3 {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
  }
  .studio-jobs-right-bar p {
    font-weight: 400;
    font-size: 20px;
    line-height: 18px;
  }
}

@media (max-width: 425px) {
  .studio-jobs-header {
    padding: 33px 22px 19px 30px;
  }
  .menu-black-icon {
    background-color: black !important;
  }
  .header-logo h3 {
    color: #434B62;
  }
  .black-logo h3 {
    font-size: 35px !important;
    line-height: 44px !important;
  }

  .studio-jobs-left-bar {
    height: 475px;
  }

  .studio-jobs-right-bar {
    padding: 30px 20px 43px 20px;
  }
  .studio-jobs-right-bar h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 18px;
  }
  .studio-jobs-right-bar p {
    font-weight: 400;
    font-size: 20px;
    line-height: 18px;
  }
}
