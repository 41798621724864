.in-progress-projects-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 29px 30px 0 30px;
}
.in-progress-projects-hide {
  overflow-y: hidden;
  height: 100vh;
}
.in-progres-projects-content {
  margin: 151px 50px;
}

@media screen and (max-width: 852px) {
  .in-progres-projects-content {
    margin: 151px 0;
  }
}