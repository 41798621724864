@import url("https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@600&display=swap");

@font-face {
  font-family: Browallia New;
  src: url("./css/browa.ttf");
}

@font-face {
  font-family: Aharoni;
  src: url("./css/aharoni.woff2");
}


@font-face {
  font-family: ArTarumianHelevetacia;
  src: url("./css/Arm\ Helvetica.ttf");
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  outline: none;
}
html {
  scroll-behavior: smooth;
}
:root{
  --bac-font: Browallia New;
  --font-size:11px;
  --bold-font:Aharoni;
  --menu-width: 8vw;
  --menu-4th-column-width: 160px;
  --menu-4th-5th-column-width: 200px;
}

.menu-icon, .menu-black-icon {
  mask-image: url(images/menu.svg) !important;
  mask-size: 27px 27px;
  mask-repeat: no-repeat;
  mask-position: center;
  width: 27px;
  height: 27px;
}

